export const get_last_monday = function ({ now = new Date(), offset_in_min = 0 } = { now: new Date(), offset_in_min: 0 }) {
	try {
		let last_monday = new Date(now);
		if (last_monday.getDay() === 0) {//special handling if today is sunday
			last_monday.setDate(last_monday.getDate() - 6);
		}
		else {
			last_monday.setDate(last_monday.getDate() - (last_monday.getDay() - 1));
		}

		last_monday.setHours(0); last_monday.setMinutes(0); last_monday.setSeconds(0); last_monday.setMilliseconds(0);
		
		let timezone_offset = new Date().getTimezoneOffset()
		const hoursToAdjust = Math.floor(Math.abs(timezone_offset) / 60)
		const minutesToAdjust = Math.abs(timezone_offset) % 60;
		last_monday.setHours(last_monday.getHours() + hoursToAdjust * (timezone_offset>0 ? -1 : 1))
		last_monday.setMinutes(last_monday.getMinutes() + minutesToAdjust * (timezone_offset>0 ? -1 : 1))

		last_monday.setTime(last_monday.getTime() + offset_in_min * 60 * 1000);
		return last_monday;
	}
	catch (err) {
		console.error(`error in get_last_monday`, err);
	}
}

