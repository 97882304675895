import React, { useEffect, useState } from "react"
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	LineElement,
	PointElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { CspModal, CspModalBody, CspModalFooter, CspModalHeader } from "../../components/CspModal";
import { colourStyles4 } from "../../utils/react_select_styles";
import general_fetch from "../../utils/fetch";
const animatedComponents = makeAnimated();

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	LineElement,
	PointElement,
	Title,
	Tooltip,
	Legend
  );
  
  export const options1 = {
	indexAxis:'y',
	responsive: true,
	plugins: {
	  legend: {
		position: 'top',
	  },
	  title: {
		display: true,
		text: 'Meetings',
	  },
	},
  };

  export const options2 = {
	indexAxis:'y',
	responsive: true,
	plugins: {
	  legend: {
		position: 'top',
	  },
	  title: {
		display: true,
		text: 'Calls',
	  },
	},
  };

  export const options3 = {
	indexAxis:'y',
	responsive: true,
	plugins: {
	  legend: {
		position: 'top',
	  },
	  title: {
		display: true,
		text: 'No of Clients contacted (Meetings only)',
	  },
	},
  };

  export const options4 = {
	indexAxis:'y',
	responsive: true,
	plugins: {
	  legend: {
		position: 'top',
	  },
	  title: {
		display: true,
		text: 'No of Clients contacted (Meetings + Calls)',
	  },
	},
  };
  

const ActivityReports = ({user_details, cs_org_users, set_page_loader, is_cs_admin, is_team_lead, infurnia_teams}) => {

	const [activities, set_activities] = useState([]);
	const [timeline, set_timeline] = useState({value:'last_30_days', label:'Last 30 Days'});
	const [meetings_data, set_meetings_data] = useState({  labels:[],datasets: [],});
	const [calls_data, set_calls_data] = useState({  labels:[],datasets: [],});
	const [unique_clients_meetings, set_unique_clients_meetings] = useState({  labels:[],datasets: [],});
	const [unique_clients_meetings_calls, set_unique_clients_meetings_calls] = useState({  labels:[],datasets: [],});

	const get_activities = async(assignee_ids) => {
		try {
			let body = {assignee_ids}
			const url =  assignee_ids && assignee_ids.length ? 'cs_backend/activity/get_by_assignee' : 'cs_backend/activity/get_all'
			// const full_url = 'https://stagebackend5.infurnia.com/' + (user_details.role && user_details.role.store && user_details.role.store.length && user_details.role.store.includes('customer_success_admin') ? 'activity/get_all' : 'activity/get_by_assignee')

		// 	fetch(full_url, {
		// 	method: 'POST', 
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// 	body: JSON.stringify(payload),
		// 	})
		// 	.then(response => {
		// 		if (!response.ok) {
		// 		throw new Error(`HTTP error! Status: ${response.status}`);
		// 		}
		// 		return response.json();
		// 	})
		// 	.then(data => {
		// 		console.log('activities', data);
		// 		set_activities(data.data);
		// })
		// 	.catch(error => {
		// 		console.error('Error:', error);
		// 	});
			set_page_loader(true, 'Fetching reports...')
			let resp = await general_fetch({url, body})
			set_page_loader(false)
			set_activities(resp);
			console.log('activitiesssssssss', resp, resp.length)
		}catch (e) {
			console.error(e)
		}
	}

	const sort_by_json_value = (a, b, json) => {
		if(!json[a]) return 1
		if(!json[b]) return -1
		if(json[a] < json[b]){
			return 1
		}else{
			return -1
		}
	}

	const sort_by_json_length = (a, b, json) => {
		if(!json[a]) return 1
		if(!json[b]) return -1
		if(Object.keys(json[a]).length < Object.keys(json[b]).length){
			return 1
		}else{
			return -1
		}
	}

	useEffect(() => {
		if(user_details && user_details.id && infurnia_teams && infurnia_teams.length && (is_team_lead || is_cs_admin)){
			if(is_team_lead && !is_cs_admin){
				let team_members = []
				infurnia_teams.map(team => {
					if(team.team_lead_id === user_details.id){
						team_members = team_members.concat(team.users)
					}
				})
				if(!team_members.includes(user_details.id)){
					team_members.push(user_details.id)
				}
				get_activities(team_members)
			}else if(is_cs_admin){
				get_activities()
			}
		}
	}, [user_details, infurnia_teams, is_team_lead, is_cs_admin]);

	useEffect(() => {
		if(activities && activities.length && cs_org_users && cs_org_users.length){
			const today = new Date(new Date().toDateString());
			const currentDay = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
			const startOfWeek = new Date(today);
			startOfWeek.setDate(today.getDate() - currentDay + 1); // Set to the first day of the week (Monday)

			const endOfWeek = new Date(startOfWeek);
			endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to the last day of the week (Sunday)

			const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Set to the first day of the month
			const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Set to the last day of the month

			let from_date = new Date(new Date().toDateString()), to_date = new Date(new Date().toDateString())
			if(timeline.value === 'last_30_days'){
				from_date.setDate(from_date.getDate()-30)
				// to_date.setDate(to_date.getDate()-1)
			}else if(timeline.value === 'last_7_days'){
				from_date.setDate(from_date.getDate()-7)
				// to_date.setDate(to_date.getDate()-1)
			}else if(timeline.value === 'this_week'){
				from_date = new Date(startOfWeek)
				to_date = new Date(endOfWeek)
			}else{
				from_date = new Date(startOfMonth)
				to_date = new Date(endOfMonth)
			}
			let temp_activities = activities.filter(o => new Date(new Date(o.due_date).toDateString()) >= new Date(from_date) && new Date(new Date(o.due_date).toDateString()) <= new Date(to_date))
			let meetings = temp_activities.filter(o => o.type==='meeting')
	
			let meetings_json = {}
			if(meetings && meetings.length){
				meetings.map(x => {if(meetings_json[x.assignee_id]){
										meetings_json[x.assignee_id]++
									}else{
										meetings_json[x.assignee_id] = 1
									}})
			}
			let ids = Object.keys(meetings_json).sort((a,b) => sort_by_json_value(a, b, meetings_json))
			set_meetings_data({
				labels: ids && ids.length ? ids.map(x => cs_org_users.find(o => o.id === x).first_name) : [],
				datasets:[
					{
						label:'Number of Meetings',
						data:ids.map((x) => meetings_json[x]),
						backgroundColor:'#ADD8E6', 
						borderColor:'#5e72e4'
					}
				],
				fill:false
			})
	
			let calls = temp_activities.filter(o => o.type==='call')

			let calls_json = {}
			if(calls && calls.length){
				calls.map(x => {if(calls_json[x.assignee_id]){
										calls_json[x.assignee_id]++
									}else{
										calls_json[x.assignee_id] = 1
									}})
			}
			ids = Object.keys(calls_json).sort((a,b) => sort_by_json_value(a, b, calls_json))
			set_calls_data({
				labels: ids && ids.length ? ids.map(x => cs_org_users.find(o => o.id === x).first_name) : [],
				datasets:[
					{
						label:'Number of Calls',
						data:ids.map((x) => calls_json[x]),
						backgroundColor:'#96ca96', 
						borderColor:'#5e72e4'
					}
				]
			})

			let unique_clients_meetings_json = {}
			if(meetings && meetings.length){
				meetings.map(x => {if(unique_clients_meetings_json[x.assignee_id] && x.org_id){
										unique_clients_meetings_json[x.assignee_id][x.org_id] = 1
									}else if(x.org_id){
										unique_clients_meetings_json[x.assignee_id] = {}
										unique_clients_meetings_json[x.assignee_id][x.org_id] = 1
									}})
			}
			ids = Object.keys(unique_clients_meetings_json).sort((a,b) => sort_by_json_length(a, b, unique_clients_meetings_json))
			set_unique_clients_meetings({
				labels: ids && ids.length ? ids.map(x => cs_org_users.find(o => o.id === x).first_name) : [],
				datasets:[
					{
						label:'Number of Clients',
						data:ids.map((x) => Object.keys(unique_clients_meetings_json[x]).length),
						backgroundColor:'#FFB6C1', 
						borderColor:'#5e72e4'
					}
				],
				fill:false
			})

			let unique_clients_meetings_calls_json = {}
			let meetings_calls = temp_activities.filter(o => o.type==='meeting' || o.type==='call')
			if(meetings_calls && meetings_calls.length){
				meetings_calls.map(x => {if(unique_clients_meetings_calls_json[x.assignee_id] && x.org_id){
											unique_clients_meetings_calls_json[x.assignee_id][x.org_id] = 1
										}else if(x.org_id){
											unique_clients_meetings_calls_json[x.assignee_id] = {}
											unique_clients_meetings_calls_json[x.assignee_id][x.org_id] = 1
										}})
			}
			ids = Object.keys(unique_clients_meetings_calls_json).sort((a,b) => sort_by_json_length(a, b, unique_clients_meetings_calls_json))
			set_unique_clients_meetings_calls({
				labels: ids && ids.length ? ids.map(x => cs_org_users.find(o => o.id === x).first_name) : [],
				datasets:[
					{
						label:'Number of Clients',
						data:ids.map((x) => Object.keys(unique_clients_meetings_calls_json[x]).length),
						backgroundColor:'#D8BFD8', 
						borderColor:'#5e72e4'
					}
				],
				fill:false
			})
			console.log('unique_clients_meetings_calls_json', unique_clients_meetings_json, unique_clients_meetings_calls_json)
		}
	}, [timeline, activities, cs_org_users]);

	return(
		<div className="p-4">
			<div className="flex_property" style={{justifyContent:'space-between'}}>
				<div>Activity Reports</div>
				<div style={{fontSize:'14px'}}>
					<Select 
						// closeMenuOnSelect={false}
						components={animatedComponents}
						value = {timeline}
						// isMulti
						options={[{value:'this_week', label:'This Week'}, {value:'this_month', label:'This Month'}, {value:'last_7_days', label:'Last 7 Days'}, {value:'last_30_days', label:'Last 30 Days'}]}
						styles={colourStyles4}
						onChange={(selectedOptions) => {set_timeline(selectedOptions)}}
					/>
				</div>
			</div>
			<div className="px-4">
				<Bar options={options1} data={meetings_data} />
				<br/>
				<Bar options={options2} data={calls_data} />
				<br/>
				<Bar options={options3} data={unique_clients_meetings} />
				<br/>
				<Bar options={options4} data={unique_clients_meetings_calls} />
			</div>
		</div>
	)
}

export default ActivityReports