import React,{ useEffect, useState, useMemo } from "react";
import Select from 'react-select';
import { addDays, set } from "date-fns";
import makeAnimated from 'react-select/animated';
import { Table } from "reactstrap";
import { colourStyles } from "../../utils/react_select_styles";
import mixpanel_event_types from "../../utils/mixpanel_event_types";
import DateRangeModal from "../DateRangeModal";
import { Switch } from 'antd';
const animatedComponents = makeAnimated();
const styles = {
	header_row: {
		marginLeft: 0,
		marginRight: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	round_status: {
		fontSize: 'small',
		paddingBottom: 0
	},
	nav_pills_rounds: {
		paddingRight: 40,
		marginRight: "0.7rem",
		marginLeft: "0.2rem",
	},
	action_button: {
		margin: 1
	}
};

const UsersTab = ({store_info}) => {
	const [usage_duration_filter, set_usage_duration_filter] = useState('last_30_days');
	const [usage_start_time, set_usage_start_time] = useState(addDays(new Date(new Date().toDateString()), -31));
	const [usage_end_time, set_usage_end_time] = useState(new Date(new Date().toDateString()));
	const [show_usage_date_range, set_show_usage_date_range] = useState(false);
	const [selected_usage_events, set_selected_usage_events] = useState([{ value: 'version_save', label: 'Version Save' }]);
	const [mixpanel_user_level_events, set_mixpanel_user_level_events] = useState({});
	const [org_users, set_org_users] = useState([]);
	const [sorted_asc_by, set_sorted_asc_by] = useState({});
    const [roles_options, set_roles_options] = useState([]);
    const [selected_roles, set_selected_roles] = useState();
    const [exclude_mes_only_users, set_exclude_mes_only_users] = useState(true);

	const fetch_user_level_mixpanel_events = async (start_time, end_time) => {
		try {
			const apiUrl = global.config.analytics_path + 'api/user_level_events'; // Replace with your API URL
			const payload = {
				start_time: start_time.toISOString(), // Replace with your start_time
				end_time: end_time.toISOString(),   // Replace with your end_time
				org_id: window.location.pathname.split('/')[3]
			};

			// Make the POST request using fetch
			fetch(apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			})
				.then(response => {
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then(data => {
					// Process the response data
					console.log('Response data cassandra:', data);
					set_mixpanel_user_level_events(data)
				})
				.catch(error => {
					console.error('Error:', error);
				});

		} catch (err) {
			console.error(err)
		}
	}

	const event_sort = (a, b, order = 1) => {
		let number_a = mixpanel_user_level_events[a.id] && selected_usage_events && selected_usage_events.length ? selected_usage_events.reduce((final, event) => mixpanel_user_level_events[a.id][event.value] ? final + mixpanel_user_level_events[a.id][event.value] : final, 0) : 0
		let number_b = mixpanel_user_level_events[b.id] && selected_usage_events && selected_usage_events.length ? selected_usage_events.reduce((final, event) => mixpanel_user_level_events[b.id][event.value] ? final + mixpanel_user_level_events[b.id][event.value] : final, 0) : 0

		if (number_a > number_b) {
			return -1 * order;
		} else {
			return 1 * order;
		}
	}

	const sort_by_mixpanel_events = () => {
		let temp_users = JSON.parse(JSON.stringify(org_users))
		temp_users.sort((a,b) => event_sort(a,b,sorted_asc_by['mixpanel_events']?1:-1))
		set_org_users(temp_users)
		sorted_asc_by['mixpanel_events'] = !sorted_asc_by['mixpanel_events']
	}

    const get_uniq_roles = (roles) => {
        let uniq = Array.from(new Set(roles));
        uniq.sort((a, b) => a.localeCompare(b));
        return uniq;
    }

	useEffect(() => {
		if (usage_duration_filter && usage_duration_filter != 'custom') {
			let from_date = new Date(), to_date = new Date()
			if (usage_duration_filter == 'yesterday') {
				from_date = new Date(new Date().toDateString())
				to_date = new Date(new Date().toDateString())
				from_date.setDate(from_date.getDate() - 1)
			} else if (usage_duration_filter == 'last_7_days') {
				from_date.setDate(from_date.getDate() - 7)
			} else if (usage_duration_filter == 'last_30_days') {
				from_date.setDate(from_date.getDate() - 30)
			}else if(usage_duration_filter == 'today') {
				from_date = new Date(new Date().toDateString())
			}
			fetch_user_level_mixpanel_events(from_date, to_date)
		}
	}, [usage_duration_filter]);

	useEffect(() => {
		if(store_info && store_info.all_users && store_info.all_users.length) {
            let non_internal_users = store_info.all_users.filter(x => !x.internal).filter(x => !x.deprecated);
            let all_roles = Array.from(new Set(non_internal_users.map(x => x.roles).flat()));
            let v = all_roles.map(x => ({ value: x, label: x }));
            set_roles_options(v);
            set_org_users(non_internal_users)
        }
	}, [store_info]);

    const display_users = useMemo(() => {
        let temp_users = JSON.parse(JSON.stringify(org_users))
        if(selected_roles && selected_roles.length) {
            temp_users = temp_users.filter(x => selected_roles.map(y => y.value).every(z => x.roles.includes(z)))
        }
        if(exclude_mes_only_users) {
            temp_users = temp_users.filter(x => {
                let r = Array.from(new Set(x.roles));
                r = r.filter(x => x != 'mes_operator');
                r = r.filter(x => x != 'mes_admin');
                if(r.length > 0) {
                    return true
                }
                return false
            })
        }
        return temp_users
    }, [org_users, selected_roles, exclude_mes_only_users])

	return(
		<>
		<div className="flex_property mb-4" style={{ justifyContent: 'space-between' }}>
			<div className="custom_time_filter">
				<div
					className={`nav-item ${usage_duration_filter === 'today' && 'selected'}`}
					onClick={() => { set_usage_duration_filter('today'); }}
				>
					Usage Today
				</div>
				<div
					className={`nav-item ${usage_duration_filter === 'yesterday' && 'selected'}`}
					onClick={() => { set_usage_duration_filter('yesterday'); }}
				>
					Usage Yesterday
				</div>
				<div
					className={`nav-item ${usage_duration_filter === 'last_7_days' && 'selected'}`}
					onClick={() => { set_usage_duration_filter('last_7_days'); }}
				>
					Last 7 days
				</div>
				<div
					className={`nav-item ${usage_duration_filter === 'last_30_days' && 'selected'}`}
					onClick={() => { set_usage_duration_filter('last_30_days'); }}
				>
					Last 30 days
				</div>
				<div
					className={`nav-item ${usage_duration_filter === 'custom' && 'selected'}`}
					onClick={() => { set_show_usage_date_range(true) }}
				>
					{usage_duration_filter === 'custom' ? usage_start_time.toDateString().substring(4, 10) + ' - ' + usage_end_time.toDateString().substring(4, 10) : 'Select Period'}
					<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: '10', boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)' }}>
					</div>
				</div>
			</div>
			<div className="flex_center" style={{ fontSize: '12px' }}>
                <div className="flex_center mr-2" style={{ fontSize: '12px' }}>
                    Exclude MES Only Users:
                    <Switch size="small" className="mx-2" checked={exclude_mes_only_users} onChange={() => set_exclude_mes_only_users(!exclude_mes_only_users)} />
                    <div className="mr-2">Roles:</div>
                    <Select
                        // closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={selected_roles}
                        isMulti
                        options={roles_options}
                        styles={colourStyles}
                        onChange={(selectedOptions) => set_selected_roles(selectedOptions)}
                    />
                </div>
				<div className="mr-2">Usage Events:</div>
				<Select
					// closeMenuOnSelect={false}
					components={animatedComponents}
					value={selected_usage_events}
					isMulti
					options={mixpanel_event_types}
					styles={colourStyles}
					onChange={(selectedOptions) => set_selected_usage_events(selectedOptions)}
				/>
			</div>
		</div>
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr style={{ textAlign: 'left' }}>
					<th scope="col"><span style={styles.table_header}><b>S.No</b></span>&nbsp;</th>
					<th scope="col"><span style={styles.table_header}><b>First Name</b></span>&nbsp;</th>
					<th scope="col"><span style={styles.table_header}><b>Email</b></span>&nbsp;</th>
					<th scope="col"><span style={styles.table_header}><b>Roles</b></span>&nbsp;</th>
					<th scope="col" className="text-right"><span style={styles.table_header}><b>Usage <i className='fa fa-sort ml-1 cp' onClick={() => sort_by_mixpanel_events()} /></b></span>&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				{
					display_users && display_users.length ? display_users.map((single_user, idx) => (
						<tr style={{ textAlign: 'left' }}>
							<td scope="col"><span>{idx + 1}</span>&nbsp;</td>
							<td scope="col"><span>{single_user.first_name ? single_user.first_name : 'N/A'}</span>&nbsp;</td>
							<td scope="col"><span>{single_user.email ? single_user.email : 'N/A'}</span>&nbsp;</td>
							<td scope="col">
                                <div>
                                    { 
                                        get_uniq_roles(single_user.roles).map((role, idx) => (
                                            <div>
                                                {role}
                                            </div>
                                        ))
                                    }
                                </div>
                            </td>
							<td scope="col" className="text-right"><span>{single_user.id && mixpanel_user_level_events[single_user.id] && selected_usage_events && selected_usage_events.length ? selected_usage_events.reduce((final, event) => mixpanel_user_level_events[single_user.id][event.value] ? final + mixpanel_user_level_events[single_user.id][event.value] : final, 0).toLocaleString() : "0"}</span>&nbsp;</td>
						</tr>
					)) : ''
				}
			</tbody>
		</Table>
		<DateRangeModal
			open={show_usage_date_range}
			toggle={() => set_show_usage_date_range(false)}
			dateRange={[{ startDate: usage_start_time, endDate: usage_end_time, key: 'selection' }]}
			setRange={(x, y) => { set_usage_start_time(x); set_usage_end_time(y); set_usage_duration_filter('custom') }}
			filter_fn={(x, y) => fetch_user_level_mixpanel_events(x, y)}
		/>
	</>
	)
			}

export default UsersTab