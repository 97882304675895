function isWithinNext7Days(date) {
	// Get the current date
	const currentDate = new Date(new Date().toDateString())
  
	// Calculate the date 7 days from now
	const next7Days = new Date(new Date().toDateString())
	next7Days.setDate(currentDate.getDate() + 7);
  
	// Check if the given date is less than or equal to 7 days from now
	return new Date(date) <= new Date(next7Days) && new Date(date) >= new Date(currentDate);
  }

const filter_fn = (search_text, original_stores, set_stores, selectedNavItem, all_new_renewed_filter, enterprise_filter, suspended_filter, internal_filter, selected_cses, se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, due_date_filter_1, due_date_from_1, due_date_to_1, suspension_date_filter_1, suspension_date_from_1, suspension_date_to_1, churn_date_filter_1, churn_date_from_1, churn_date_to_1, last_billed_date_filter_1, last_billed_date_from_1, last_billed_date_to_1, license_start_date_filter_1, license_start_date_from_1, license_start_date_to_1, last_trial_date_filter_1, last_trial_date_from_1, last_trial_date_to_1, next_billing_date_filter_1, next_billing_date_from_1, next_billing_date_to_1, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter) => {
	if(original_stores && original_stores.length){
		let all_stores = JSON.parse(JSON.stringify(original_stores))
		// console.log('filtersssssss', this.state.premium_filter, this.state.enterprise_filter, this.state.suspended_filter)
		all_stores = all_stores.filter(o => {
			if((o.name && o.name.toLowerCase().includes(search_text.toLowerCase())) || o.id === search_text.toLowerCase()) return true

			if(o.location){
				let location = o.location
				let location_string = location.line_1 + location.line_2 + location.city + location.state + location.country
				if(location_string.toLowerCase().includes(search_text.toLowerCase())) return true
			}
            if(o.users && o.users.length) {
                let emails = o.users.map(x => x.email).join(' ');
                let first_names = o.users.map(x => x.first_name).join(' ');
                if(emails.toLowerCase().includes(search_text.toLowerCase())) return true
                if(first_names.toLowerCase().includes(search_text.toLowerCase())) return true
            }

			return false
		})

		console.log('last_billed_date_filter_1',last_billed_date_filter_1, 'next_billing_date_filter_1',next_billing_date_filter_1)

		if(selectedNavItem == 1){
			all_stores = all_stores.filter(o => o.premium_customer && !o.due_date && !o.suspended && !o.churned && !isWithinNext7Days(new Date(o.next_billing_date).toDateString()))
		}else if(selectedNavItem == 2){
			all_stores = all_stores.filter(o => o.premium_customer && o.due_date && !o.suspended && !o.churned)
		}else if(selectedNavItem == 3){
			all_stores = all_stores.filter(o => o.premium_customer && o.suspended && !o.churned)
		}else if(selectedNavItem == 4){
			console.log('ALL STORES TRIGGERED 55');
			all_stores = all_stores.filter(o => o.churned)
		}else if(selectedNavItem == 5){
			all_stores = all_stores.filter(o => !o.premium_customer && !o.suspended)
		}else if(selectedNavItem == 6){
			all_stores = all_stores.filter(o => !o.premium_customer && o.suspended && !(o.refund_entry && o.refund_entry.id))
		}else if(selectedNavItem == 8){
			all_stores = all_stores.filter(o => !o.premium_customer && o.suspended && o.refund_entry && o.refund_entry.id)
		}else if(selectedNavItem == 0){
			all_stores = all_stores.filter(o => o.premium_customer && !o.churned)
		}else if(selectedNavItem == 7){
			all_stores = all_stores.filter(o => o.premium_customer && !o.due_date && !o.suspended && !o.churned && isWithinNext7Days(new Date(o.next_billing_date).toDateString()))
		}

		if(all_new_renewed_filter === 'new'){
			all_stores = all_stores.filter(o => !o.renewed_once)
		}else if(all_new_renewed_filter === 'renewed'){
			all_stores = all_stores.filter(o => o.renewed_once)
		}
		
		// if(premium_filter != ''){
		// 	if(premium_filter.toString() == 'true'){
		// 		// console.log('filtersssssss 1', premium_filter)
		// 		all_stores = all_stores.filter(o => o.premium_customer)
		// 	}else{
		// 		all_stores = all_stores.filter(o => !o.premium_customer)
		// 	}
		// }

		if(enterprise_filter == 'enterprise'){
			all_stores = all_stores.filter(o => o.enterprise)
		}else if(enterprise_filter == 'smb'){
			all_stores = all_stores.filter(o => !o.enterprise)
		}

		if(suspended_filter !== undefined && suspended_filter != ''){
			if(suspended_filter.toString() == 'true'){
				// console.log('filtersssssss 3', premium_filter, enterprise_filter, suspended_filter)
				all_stores = all_stores.filter(o => o.suspended)
			}else{
				all_stores = all_stores.filter(o => !o.suspended)
			}
		}
		

		if(internal_filter !== undefined && internal_filter != ''){
			// console.log('filtersssssss 34', premium_filter, enterprise_filter, internal_filter)
			if(internal_filter.toString() == 'true'){
				// console.log('filtersssssss 34', internal_filter)
				all_stores = all_stores.filter(o => o.internal)
			}else{
				// console.log('filtersssssss 34', internal_filter)
				all_stores = all_stores.filter(o => !o.internal)
			}
		}

		if(payment_cycle_filter){
			all_stores = all_stores.filter(o => o.payment_cycle == payment_cycle_filter)
		}

		if(selected_cses && selected_cses.length){
			all_stores = all_stores.filter(o => selected_cses.find(x => x.value === o.customer_success_executive_id))
		}

		if(country_filter && country_filter.length){
			all_stores = all_stores.filter(o => country_filter.find(x => x.value === o.country))
		}

		if(se_filter != ''){
			all_stores = all_stores.filter(o => o.sales_executive_id == se_filter)
		}

		if(onboarding_filter != ''){
			all_stores = all_stores.filter(o => o.onboarding_status == onboarding_filter)
		}

		console.log('selected_domains', selected_domains)
		if(responsiveness_filter !== undefined && responsiveness_filter != ''){
			if(responsiveness_filter.toString() == 'true'){
				all_stores = all_stores.filter(o => o.responsiveness)
			}else{
				all_stores = all_stores.filter(o => !o.responsiveness)
			}
		}

		if(selected_domains && selected_domains.length > 0){
			let selected_domains_values = selected_domains.map(x => x.value)
			all_stores = all_stores.filter(o => selected_domains_values.includes(o.domain))
		}

		if(selected_add_ons && selected_add_ons.length > 0){
			let selected_add_on_ids = selected_add_ons.map(x => x.value)
			if(selected_add_on_ids.length == 1 && selected_add_on_ids[0] == 'design'){
				all_stores = all_stores.filter(o => {
                    const has_pricing_add_on = o.active_add_on_ids && o.active_add_on_ids.length && o.active_add_on_ids.some(item => ['5'].includes(item))
                    const has_any_mes_add_on = o.active_add_on_ids && o.active_add_on_ids.length && o.active_add_on_ids.some(item => ['1', '4', '6', '7'].includes(item))
                    return !has_pricing_add_on && !has_any_mes_add_on;
                })
            } else if(selected_add_on_ids.length == 1 && selected_add_on_ids[0] == 'design_pricing'){
				all_stores = all_stores.filter(o => {
                    const has_pricing_add_on = o.active_add_on_ids && o.active_add_on_ids.length && o.active_add_on_ids.some(item => ['5'].includes(item))
                    const has_any_mes_add_on = o.active_add_on_ids && o.active_add_on_ids.length && o.active_add_on_ids.some(item => ['1', '4', '6', '7'].includes(item))
                    return has_pricing_add_on && !has_any_mes_add_on
                })
            } else if(selected_add_on_ids.length == 1 && selected_add_on_ids[0] == 'design_pricing_mes'){
				all_stores = all_stores.filter(o => { 
                    const has_any_mes_add_on = o.active_add_on_ids && o.active_add_on_ids.length && o.active_add_on_ids.some(item => ['1', '4', '6', '7'].includes(item))
                    return has_any_mes_add_on;
                })
			}else{
				all_stores = all_stores.filter(o => o.active_add_on_ids.some(item => selected_add_on_ids.includes(item)))
			}

			if(addon_onboarding_filter && addon_onboarding_filter != ''){
				if(selected_add_on_ids.includes('1') || selected_add_on_ids.includes('7')){
					all_stores = all_stores.filter(o => o.onboarding_metadata && (o.onboarding_metadata.manuf_onboarding_status == addon_onboarding_filter))
				}
				if(selected_add_on_ids.includes('4') || selected_add_on_ids.includes('6')){
					all_stores = all_stores.filter(o => o.onboarding_metadata && (o.onboarding_metadata.cutlist_onboarding_status == addon_onboarding_filter))
				}
				if(selected_add_on_ids.includes('5')){
					all_stores = all_stores.filter(o => o.onboarding_metadata && (o.onboarding_metadata.adv_pric_onboarding_status == addon_onboarding_filter))
				}
			}

			if(addon_onboarding_date_from && addon_onboarding_date_to){
				console.log("addon_onboarding_date_from", addon_onboarding_date_from, addon_onboarding_date_to, all_stores)
				if(selected_add_on_ids.includes('1') || selected_add_on_ids.includes('7')){
					all_stores = all_stores.filter(o => o.onboarding_metadata && (new Date(o.onboarding_metadata.manuf_onboarding_date) >= new Date(addon_onboarding_date_from)) && (new Date(o.onboarding_metadata.manuf_onboarding_date) <= new Date(addon_onboarding_date_to)))
				}
				if(selected_add_on_ids.includes('4') || selected_add_on_ids.includes('6')){
					all_stores = all_stores.filter(o => o.onboarding_metadata && (new Date(o.onboarding_metadata.cutlist_onboarding_date) >= new Date(addon_onboarding_date_from)) && (new Date(o.onboarding_metadata.cutlist_onboarding_date) <= new Date(addon_onboarding_date_to)))
				}
				if(selected_add_on_ids.includes('5')){
					all_stores = all_stores.filter(o => o.onboarding_metadata && (new Date(o.onboarding_metadata.adv_pric_onboarding_date) >= new Date(addon_onboarding_date_from)) && (new Date(o.onboarding_metadata.adv_pric_onboarding_date) <= new Date(addon_onboarding_date_to)))
				}
			}
		}

		if(due_date_filter_1 != '' && selectedNavItem === 2){
			let from_date = new Date(new Date().toDateString()), to_date = new Date(new Date().toDateString())
			if(due_date_filter_1 === 'yesterday'){
				from_date.setDate(from_date.getDate()-1)
				to_date = from_date
			}else if(due_date_filter_1 === 'tomorrow'){
				from_date.setDate(from_date.getDate()+1)
				to_date = from_date
			}else if(due_date_filter_1 === 'next_30_days'){
				from_date.setDate(from_date.getDate())
				to_date.setDate(to_date.getDate()+29)
			}else if(due_date_filter_1 === 'next_7_days'){
				from_date.setDate(from_date.getDate())
				to_date.setDate(to_date.getDate()+6)
			}else if(due_date_filter_1 === 'custom'){
				from_date = new Date(due_date_from_1)
				to_date = new Date(due_date_to_1)
			}

			all_stores = all_stores.filter(o => new Date(new Date(o.due_date).toDateString()) >= new Date(from_date) && new Date(new Date(o.due_date).toDateString()) <= new Date(to_date))
		}

		if(suspension_date_filter_1 != '' && selectedNavItem === 3){
			let from_date = new Date(new Date().toDateString()), to_date = new Date(new Date().toDateString())
			if(suspension_date_filter_1 === 'yesterday'){
				from_date.setDate(from_date.getDate()-1)
				to_date = from_date
			}else if(suspension_date_filter_1 === 'last_7_days'){
				from_date.setDate(from_date.getDate()-7)
				// to_date.setDate(to_date.getDate()-1)
			}else if(suspension_date_filter_1 === 'last_30_days'){
				from_date.setDate(from_date.getDate()-30)
				// to_date.setDate(to_date.getDate()-1)
			}else if(suspension_date_filter_1 === 'custom'){
				from_date = new Date(suspension_date_from_1)
				to_date = new Date(suspension_date_to_1)
			}

			all_stores = all_stores.filter(o => new Date(new Date(o.last_suspended_date).toDateString()) >= new Date(from_date) && new Date(new Date(o.last_suspended_date).toDateString()) <= new Date(to_date))
		}

		if(churn_date_filter_1 != '' && selectedNavItem === 4){
			let from_date = new Date(new Date().toDateString()), to_date = new Date(new Date().toDateString())
			if(churn_date_filter_1 === 'yesterday'){
				from_date.setDate(from_date.getDate()-1)
				to_date = from_date
			}else if(churn_date_filter_1 === 'last_7_days'){
				from_date.setDate(from_date.getDate()-7)
				// to_date.setDate(to_date.getDate()-1)
			}else if(churn_date_filter_1 === 'last_30_days'){
				from_date.setDate(from_date.getDate()-30)
				// to_date.setDate(to_date.getDate()-1)
			}else if(churn_date_filter_1 === 'custom'){
				from_date = new Date(churn_date_from_1)
				to_date = new Date(churn_date_to_1)
			}

			all_stores = all_stores.filter(o => new Date(new Date(o.churn_date).toDateString()) >= new Date(from_date) && new Date(new Date(o.churn_date).toDateString()) <= new Date(to_date))
		}

		if(last_billed_date_filter_1 != '' && selectedNavItem === 1){
			let from_date = new Date(new Date().toDateString()), to_date = new Date(new Date().toDateString())
			if(last_billed_date_filter_1 === 'yesterday'){
				from_date.setDate(from_date.getDate()-1)
				to_date = from_date
			}else if(last_billed_date_filter_1 === 'last_7_days'){
				from_date.setDate(from_date.getDate()-7)
				// to_date.setDate(to_date.getDate()-1)
			}else if(last_billed_date_filter_1 === 'last_30_days'){
				from_date.setDate(from_date.getDate()-30)
				// to_date.setDate(to_date.getDate()-1)
			}else if(last_billed_date_filter_1 === 'custom'){
				from_date = new Date(last_billed_date_from_1)
				to_date = new Date(last_billed_date_to_1)
			}

			all_stores = all_stores.filter(o => new Date(new Date(o.last_billing_date).toDateString()) >= new Date(from_date) && new Date(new Date(o.last_billing_date).toDateString()) <= new Date(to_date))
		}

		if(next_billing_date_filter_1 != '' && selectedNavItem === 1){
			let from_date = new Date(new Date().toDateString()), to_date = new Date(new Date().toDateString())
			if(next_billing_date_filter_1 === 'tomorrow'){
				from_date.setDate(from_date.getDate()+1)
				to_date = from_date
			}else if(next_billing_date_filter_1 === 'next_7_days'){
				from_date.setDate(from_date.getDate())
				to_date.setDate(to_date.getDate()+6)
			}else if(next_billing_date_filter_1 === 'next_30_days'){
				from_date.setDate(from_date.getDate())
				to_date.setDate(to_date.getDate()+29)
			}else if(next_billing_date_filter_1 === 'custom'){
				from_date = new Date(next_billing_date_from_1)
				to_date = new Date(next_billing_date_to_1)
			}

			all_stores = all_stores.filter(o => new Date(new Date(o.next_billing_date).toDateString()) >= new Date(from_date) && new Date(new Date(o.next_billing_date).toDateString()) <= new Date(to_date))
		}

		if(license_start_date_filter_1 != '' && selectedNavItem === 0){
			let from_date = new Date(new Date().toDateString()), to_date = new Date(new Date().toDateString())
			if(license_start_date_filter_1 === 'yesterday'){
				from_date.setDate(from_date.getDate()-1)
				to_date = from_date
			}else if(license_start_date_filter_1 === 'last_7_days'){
				from_date.setDate(from_date.getDate()-7)
				// to_date.setDate(to_date.getDate()-1)
			}else if(license_start_date_filter_1 === 'last_30_days'){
				from_date.setDate(from_date.getDate()-30)
				// to_date.setDate(to_date.getDate()-1)
			}else if(license_start_date_filter_1 === 'custom'){
				from_date = new Date(license_start_date_from_1)
				to_date = new Date(license_start_date_to_1)
			}

			all_stores = all_stores.filter(o => new Date(new Date(o.license_start_date).toDateString()) >= new Date(from_date) && new Date(new Date(o.license_start_date).toDateString()) <= new Date(to_date))
		}

		if(last_trial_date_filter_1 != '' && (selectedNavItem === 5 || selectedNavItem === 6)){
			let from_date = new Date(new Date().toDateString()), to_date = new Date(new Date().toDateString())
			if(last_trial_date_filter_1 === 'yesterday'){
				from_date.setDate(from_date.getDate()-1)
				to_date = from_date
			}else if(last_trial_date_filter_1 === 'last_7_days'){
				from_date.setDate(from_date.getDate()-7)
				// to_date.setDate(to_date.getDate()-1)
			}else if(last_trial_date_filter_1 === 'last_30_days'){
				from_date.setDate(from_date.getDate()-30)
				// to_date.setDate(to_date.getDate()-1)
			}else if(last_trial_date_filter_1 === 'custom'){
				from_date = new Date(last_trial_date_from_1)
				to_date = new Date(last_trial_date_to_1)
			}

			all_stores = all_stores.filter(o => new Date(new Date(o.last_trial_date).toDateString()) >= new Date(from_date) && new Date(new Date(o.last_trial_date).toDateString()) <= new Date(to_date))
		}
		
		// var pages = Math.floor((all_stores.length / page_size)) + ((all_stores.length % page_size) ? 1 : 0)
		// var all_pages = Array.apply(null, {length: pages}).map(Number.call, Number)
		// set_all_pages(all_pages)
		all_stores = all_stores.map((x, idx) => ({...x, idx: idx+1}));
		set_stores(all_stores)
	}
}

export default filter_fn