import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Modal, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, Dropdown, } from "reactstrap";
import ISearch from "../../components/ISearch"
import general_fetch from "../../utils/fetch"
import { date_string } from "../../utils/date_string";
import { DatePicker, Checkbox, Tooltip } from "antd";
import { convert_to_readable } from "../../utils/convert_to_readable";
import Select from "react-select";
import { colourStyles, colourStyles2, colourStyles4 } from "../../utils/react_select_styles";
import dayjs from 'dayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toastr } from "react-redux-toastr";
import DateRangeModal from "../../components/DateRangeModal";
import { set_csp_filter } from "../../utils/set_csp_filter";
import { get_csp_filter } from "../../utils/get_csp_filter";
import makeAnimated from 'react-select/animated';
import generic_sort from "../../utils/generic_sort";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AddEditActivity from "../../components/AddEditActivity";
import { time_string } from "../../utils/time_string";
import { confirmAlert } from "react-confirm-alert";
import DeleteNotification from "../../components/DeleteNotification";
import TablePagination from "../../components/TablePagination";
import { ITD, ITable, ITableBody, ITableRow } from "../../components/ITable/index.jsx";


const animatedComponents = makeAnimated();
const activity_types = ['call', 'meeting', 'task', 'deadline', 'email', 'onboarding']
const activity_type_icons = {'call':'phone', 'meeting':'users', 'task':'clock', 'deadline':'flag', 'email':'envelope', 'onboarding':'handshake'}
const time_filters = ['todo', 'overdue', 'today', 'tomorrow', 'last_7_days', 'next_7_days']

// Function to calculate duration between two dates in hh:mm format
function calculateDuration(startTime, endTime) {
	const durationInMilliseconds = endTime - startTime;
  
	// Convert duration to hours and minutes
	const hours = Math.floor(durationInMilliseconds / (60 * 60 * 1000));
	const minutes = Math.floor((durationInMilliseconds % (60 * 60 * 1000)) / (60 * 1000));
  
	// Format the result as hh:mm
	const formattedDuration = hours ? `${String(hours).padStart(1, '0')} hr ${String(minutes).padStart(1, '0')} min` : `${String(minutes).padStart(1,'0')} minutes`
  
	return formattedDuration;
}

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
		title: 'Confirm to submit',
		message: message,
		customUI: ({ onClose }) => {
		return (
			Custom_modal ?
			<div>
				<Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
			:
			<div className='custom-ui'>
				<DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
		);
		}
	});
}

const Activities = ({user_details, cs_org_users, all_stores, set_page_loader}) => {

	const [activities, set_activities] = useState([]);
	const [display_activities, set_display_activities] = useState([]);
	const [show_add_edit_activity, set_show_add_edit_activity] = useState(false);
	const [mode, set_mode] = useState('add');
	const [active_activity, set_active_activity] = useState();
	const [search_string, set_search_string] = useState('');
	const [selected_types, set_selected_types] = useState({'call':1, 'meeting':1, 'task':1, 'deadline':1, 'email':1, 'onboarding':1});
	const [selected_time_filter, set_selected_time_filter] = useState('todo');
	const [filter_start_date, set_filter_start_date] = useState();
	const [filter_end_date, set_filter_end_date] = useState();
	const [show_date_range, set_show_date_range] = useState(false);
	const initializedStateCount = useRef(0);
	const [filter_json_from_backend, set_filter_json_from_backend] = useState();
	const [selected_users, set_selected_users] = useState([]);
	const [sorted_by_field_json, set_sorted_by_field_json] = useState({});
	const [current_org_id, set_current_org_id] = useState();
	const [page_size, set_page_size] = useState(50);
	const [no_of_pages, set_no_of_pages] = useState(null);
	const [current_page, set_current_page] = useState(1);
	const [team_members, set_team_members] = useState([]);
	const [is_team_lead, set_is_team_lead] = useState(false);
	const [user_select_options, set_user_select_options] = useState([]);
	const [user_selected_page_size, set_user_selected_page_size] = useState(0);

	const headings = useMemo(() => [
		{
			column_name: 'Edit',
			styles:{position:"sticky", left:0, top:0, zIndex:1, backgroundColor:'white'},
			sort_fn: () => {},
			columnWidth: 'minmax(80px, 80px)'
		},
		{
			column_name: 'Done',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {} ,
			columnWidth: 'minmax(80px, 80px)'
		},
		{
			column_name: 'Subject',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {sort_by_field('subject')},
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Contact Person',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {sort_by_field('contact_person')},
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Email',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {sort_by_field('email')} ,
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Phone',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {sort_by_field('phone')} ,
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Org',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {sort_by_field('org_id')} ,
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Due Date',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {sort_by_field('due_date', 'date')} ,
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Duration',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {} ,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Add Date',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {sort_by_field('created_at', 'date')} ,
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Assigned to',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {sort_by_field('assignee_id', 'user_id')} ,
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Meeting Url',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {} ,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Note',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			sort_fn: () => {sort_by_field('note')} ,
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Delete',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:'3'},
			sort_fn: () => {} ,
			columnWidth:'minmax(100px, 100px)'
		},
	], [display_activities])  

	const [column_visibility_json, set_column_visibility_json] = useState(() => {let json = {}; headings.map(col => {json[col.column_name] = 1}); return json});

	const [show_column_selector, set_show_column_selector] = useState(false);
    const createHeaders = (headings) => {
        return headings.map((item, idx) => ({
            ...item,
            ref: ('resizable-header' + item.column_name),
            }));
    }
    const columns = useMemo(() => headings ? createHeaders(headings) : [], [headings])
	const [searched_columns, set_searched_columns] = useState(columns);
    const [column_search_text, set_column_search_text] = useState('');

	useEffect(() => {
		console.log('column_visibility_json11111', column_visibility_json)
	}, [column_visibility_json]);

	const TableData = useCallback((activity) => ({
		'Edit': <ITD className={'cp'} onClick={() => {set_active_activity(activity); set_show_add_edit_activity(true); set_mode('edit')}} style={{position:'sticky', left:0, backgroundColor:'white', textAlign:'center'}}><div className="flex_center"><i className="fa fa-edit mr-2" style={{fontSize:'14px', color:'#525f7f',textDecoration:'unset'}}/></div></ITD>,
		'Done': <ITD style={{textAlign:'center',}}>
				{
					activity.status != 'done' ? 
					<Tooltip title="Mark as done" placement="top">
						<i className="far fa-circle cp" onClick={() => update_status(activity.activity_id, 'done', activity.org_id)} style={{fontSize:'16px', color:'#525f7f'}}/>
					</Tooltip>:
					<Tooltip title="Mark as not done" placement="top">
						<i className="fa fa-check-circle cp" onClick={() => update_status(activity.activity_id, 'not_done', activity.org_id)} style={{fontSize:'18px', color:'#1aae1a'}}/>
					</Tooltip>
				}
				</ITD>,
		'Subject': <ITD className='cp' onClick={() => {set_active_activity(activity); set_show_add_edit_activity(true); set_mode('edit')}}><i className={`fa fa-${activity_type_icons[activity.type]} mr-2`} style={{fontSize:'14px'}} />{activity.subject}</ITD>,
		'Contact Person': <ITD>{activity.contact_person}</ITD>,
		'Email': <ITD>{activity.email}</ITD>,
		'Phone': <ITD>{activity.phone}</ITD>,
		'Org': <ITD style={{textDecoration:'underline', textDecorationColor:'#5e72e4'}}>
					<Link to={`/home/store_dashboard/${activity.org_id}/7`}>
						{all_stores && all_stores.length && all_stores.find(o => o.id === activity.org_id) ? all_stores.find(o => o.id === activity.org_id).name : ''}
					</Link>
				</ITD>,
		'Due Date': <ITD>{date_string(activity.due_date)}, {time_string(activity.due_date)}</ITD>,
		'Duration': <ITD>{calculateDuration(new Date(activity.due_date), new Date(activity.to_date))}</ITD>,
		'Add Date': <ITD>{date_string(activity.created_at)}, {time_string(activity.created_at)}</ITD>,
		'Assigned to': <ITD>{cs_org_users && cs_org_users.length && cs_org_users.find(o => o.id === activity.assignee_id) ? cs_org_users.find(o => o.id === activity.assignee_id).first_name : ''}</ITD>,
		'Meeting Url': <ITD><a href={activity.meeting_url} target="_blank">{activity.meeting_url}</a></ITD>,
		'Note': <ITD>{activity.note}</ITD>,
		'Delete': <ITD className={'cp'} onClick={() => {submit(() => delete_fn(activity.activity_id), ()=>{}, 'Are you sure you want to delete this Activity?', '','')}} style={{position:'sticky', left:'60px', backgroundColor:'white', textAlign:'center'}}><div className="flex_center"><i className="fas fa-trash mr-2" style={{fontSize:'13px', color:'#525f7f',textDecoration:'unset'}}/></div></ITD>
	}), [display_activities, all_stores])
	
	const fetch_team_members = async() => {
		try{
            var resp = await general_fetch({url: 'team/get'});
			if(resp && resp.length && resp.find(o => o.team_lead_id === user_details.id)){
				set_is_team_lead(true)
				let temp_team_members = []
				resp.map(team => {
					if(team.team_lead_id === user_details.id){
						temp_team_members = temp_team_members.concat(team.users)
					}
				})
				if(!temp_team_members.includes(user_details.id)){
					temp_team_members.push(user_details.id)
				}
				set_team_members(temp_team_members)
				get_activities(temp_team_members)
			}else{
				get_activities()
			}
		}catch(err){
			console.error(err)
		}
	}

	const get_activities = async(given_team_members) => {
		try {
			let payload = {
				assignee_ids: is_team_lead ? team_members : [user_details.id],
			}
			if(given_team_members && given_team_members.length) payload['assignee_ids'] = given_team_members
			const url = 'cs_backend/' + (user_details.role && user_details.role.store && user_details.role.store.length && user_details.role.store.includes('customer_success_admin') ? 'activity/get_all' : 'activity/get_by_assignee')
		// 	const full_url = 'https://stagebackend5.infurnia.com/' + (user_details.role && user_details.role.store && user_details.role.store.length && user_details.role.store.includes('customer_success_admin') ? 'activity/get_all' : 'activity/get_by_assignee')

		// 	fetch('https://stagebackend5.infurnia.com/'+(user_details.role && user_details.role.store && user_details.role.store.length && user_details.role.store.includes('customer_success_admin') ? 'activity/get_all' : 'activity/get_by_assignee'), {
		// 	method: 'POST', 
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// 	body: JSON.stringify(payload),
		// 	})
		// 	.then(response => {
		// 		if (!response.ok) {
		// 		throw new Error(`HTTP error! Status: ${response.status}`);
		// 		}
		// 		return response.json();
		// 	})
		// 	.then(data => {
		// 		console.log('activities', data);
		// 		set_activities(data.data);
		// 		console.log('activities length', data.data.length);
		// })
		// 	.catch(error => {
		// 		console.error('Error:', error);
		// 	});
			let resp = await general_fetch({url, body:payload})
			set_activities(resp);
			
			console.log('activitiesssssssss', resp, resp.length)
		}catch (e) {
			console.error(e)
		}
	}

	const delete_fn = async(id) => {
		let payload = {
			activity_id: id,
		}
		try{
			let resp = await general_fetch({url:'cs_backend/activity/delete', body:payload})
			get_activities()
		}catch(error){
			console.error('Error:', error);
		}
	}

	const update_status = async(activity_id, status, org_id) => {
		try{
			// const full_url = 'https://stagebackend5.infurnia.com/' + 'activity/update'; 
			let body = {activity_id, status}
			let resp = await general_fetch({url:'cs_backend/activity/update', body,});
			// let resp = await fetch(full_url, {
			// 						method: 'POST', 
			// 						headers: {
			// 							'Content-Type': 'application/json',
			// 						},
			// 						body: JSON.stringify(body),
			// 					})
			if(resp.error){
				toastr.error(resp.error.message)
				return
			}
			if(status === 'done'){
				set_mode('add')
				set_current_org_id(org_id)
				set_show_add_edit_activity(true)
			}
			get_activities()
		}catch(err){
			console.error('Error:', err);
			toastr.error(`Couldn't update status, please try again`)
		}
	}

	const onclick_all_types = () => {
		let all_selected = 1
		activity_types.map((single_type, index) => {
			if(!selected_types[single_type]) all_selected = 0
		})
		activity_types.map((single_type, index) => {
			all_selected ? selected_types[single_type] = 0 : selected_types[single_type] = 1 	
		})
		set_selected_types(JSON.parse(JSON.stringify(selected_types)))
	}

	const filter_activities = () => {
		let temp_activities = JSON.parse(JSON.stringify(activities))
		let search_text = search_string.toLowerCase()
		temp_activities = temp_activities.filter(o => (o.subject && o.subject.toLowerCase().includes(search_text)) || (o.contact_person && o.contact_person.toLowerCase().includes(search_text)) || (o.email && o.email.toLowerCase().includes(search_text)) || (o.org_id && o.org_id.toLowerCase().includes(search_text)))
		temp_activities = temp_activities.filter(o => selected_types[o.type])

		let from_date = new Date(new Date().toDateString()), to_date = new Date(new Date().toDateString())
		if(selected_time_filter === 'todo'){
			temp_activities = temp_activities.filter(o => o.status != 'done')
		}else if(selected_time_filter === 'overdue'){
			temp_activities = temp_activities.filter(o => o.status != 'done' && new Date() > new Date(o.due_date))
		}else{
			if(selected_time_filter === 'tomorrow'){
				from_date.setDate(from_date.getDate()+1)
				to_date = from_date
			}else if(selected_time_filter === 'last_7_days'){
				from_date.setDate(from_date.getDate()-7)
				// to_date.setDate(to_date.getDate()-1)
			}else if(selected_time_filter === 'next_7_days'){
				to_date.setDate(to_date.getDate()+6)
			}else if(selected_time_filter === 'custom'){
				from_date = new Date(filter_start_date)
				to_date = new Date(filter_end_date)
			}
			temp_activities = temp_activities.filter(o => new Date(new Date(o.due_date).toDateString()) >= new Date(from_date) && new Date(new Date(o.due_date).toDateString()) <= new Date(to_date))
		}

		if(selected_users && selected_users.length){
			let user_ids = selected_users.map((user) => user.value)
			temp_activities = temp_activities.filter(o => user_ids.includes(o.assignee_id))
		}

		set_display_activities(temp_activities)
	}

	const sort_by_field = (field, type) => {
		let temp_activities = JSON.parse(JSON.stringify(display_activities)) 
		console.log('sorted_by_field_json[field]', sorted_by_field_json, temp_activities)
		temp_activities.sort((a,b) => generic_sort(a, b , field, sorted_by_field_json[field] ? -1 : 1, type))
		sorted_by_field_json[field] = !sorted_by_field_json[field]
		set_display_activities(temp_activities)
	}

	useEffect(() => {
		if(user_details && user_details.id && cs_org_users && cs_org_users.length){
			fetch_team_members()
		}
	}, [user_details, cs_org_users]);

	useEffect(() => {
		set_display_activities(activities)
	}, [activities]);

	useEffect(() => {
		filter_activities()
	}, [activities, search_string, selected_types, selected_time_filter, filter_start_date, filter_end_date, selected_users]);

	useEffect(() => {
		if(user_details && user_details.id && cs_org_users && cs_org_users.length) get_csp_filter(user_details.id, '/home/activities', set_filter_json_from_backend)
	}, [user_details, cs_org_users]);

	useEffect(() => {
		if(initializedStateCount.current < 2){
			initializedStateCount.current += 1
		}else if(user_details && user_details.id){
			let filter_json = {search_string, selected_types, selected_time_filter, filter_start_date, filter_end_date, selected_users, column_visibility_json, user_selected_page_size}
			set_csp_filter(user_details.id, '/home/activities', '0', filter_json)
		}
	}, [user_details, search_string, selected_types, selected_time_filter, filter_start_date, filter_end_date, selected_users, column_visibility_json, user_selected_page_size]);

	useEffect(() => {
		if(filter_json_from_backend && filter_json_from_backend['/home/activities']){
			let final_json = JSON.parse(filter_json_from_backend['/home/activities'][0])
			if(final_json){
				set_search_string(final_json.search_string)
				set_selected_types(final_json.selected_types)
				set_selected_time_filter(final_json.selected_time_filter)
				set_filter_start_date(new Date(final_json.filter_start_date))
				set_filter_end_date(new Date(final_json.filter_end_date))
				set_selected_users(final_json.selected_users)
				if(Number(final_json.user_selected_page_size)){
					set_user_selected_page_size(Number(final_json.user_selected_page_size))
					set_page_size(Number(final_json.user_selected_page_size))
				}
				// set_column_visibility_json(final_json.column_visibility_json ? final_json.column_visibility_json : column_visibility_json)
				if(final_json.column_visibility_json){
					headings.map((key) => {
						if(final_json.column_visibility_json[key.column_name] || final_json.column_visibility_json[key.column_name]==undefined){
							column_visibility_json[key.column_name] = true
						}else{
							column_visibility_json[key.column_name] = false
						}
					})
				}
			}
		}
	}, [filter_json_from_backend]);

	useEffect(() => {
		if(document.getElementById('activities_header') && document.getElementById('activities_table')){
			document.getElementById('activities_table').style.height = window.innerHeight - document.getElementById('activities_header').offsetHeight + 'px'
		}
	}, []);

	useEffect(() => {
		if(display_activities && display_activities.length && !user_selected_page_size){
			let x1 = document.getElementById('activities_header').offsetHeight
			let x2 = 52
			let x3 = 45
			let remaining_height = window.innerHeight - x1 - x2 - x3
			let page_size1 = Math.floor(remaining_height/45)
			set_page_size(Math.floor(page_size1))
			console.log('no of pages', no_of_pages, page_size1, display_activities.length, x1, x2, remaining_height, window.innerHeight)
		}
	}, [display_activities]);

	useEffect(() => {
		if(display_activities && display_activities.length){
			let pages = Math.floor((display_activities.length / page_size)) + ((display_activities.length % page_size) ? 1 : 0)
			set_no_of_pages(pages)
		}else{
			set_no_of_pages(1)
		}
	}, [page_size, display_activities]);

	useEffect(() => {
		if(user_details && cs_org_users && cs_org_users.length){
			if(user_details.role && user_details.role.store && user_details.role.store.length && user_details.role.store.includes('customer_success_admin')){
				set_user_select_options(cs_org_users && cs_org_users.length ? cs_org_users.map(x => {return {value: x.id, label: x.first_name}}) : [])
			}else if(team_members && team_members.length){
				set_user_select_options(team_members.map(x => {return {value: x, label: cs_org_users.find(o => o.id===x).first_name}}))
			}
		}
	}, [user_details, cs_org_users, team_members]);

	const update_column_visibility_json = (column_name, value) => {
		let a = JSON.parse(JSON.stringify(column_visibility_json))
		a[column_name] = value
		set_column_visibility_json(a)
	}

	const column_selector = (
		<div className="p-3" style={{ color: '#323338', display:'flex', flexDirection:'column', height:'380px' }}>
			<div>
				<div className="mb-3" style={{ color: '#A7A8B2', color:'black', fontSize:'15px'}}>Cutomize Columns</div>
				<div className="flex_property justify-between mb-3">
					<ISearch style_outer={{width:'300px'}} style_inner={{width:'300px'}} search_text={column_search_text} set_search_text={set_column_search_text} list={columns} set_list={set_searched_columns} search_parameters={['column_name']}/>
				</div>
				<hr className='mb-3 mt-0' />
			</div>
			<div style={{flex:1, overflow:'auto'}}>
			{
				searched_columns.map(column => (
					<div className="flex_property justify-between" style={{marginBottom:'12px'}}>
						<div className="flex_property">
							<Checkbox checked={column_visibility_json[column.column_name]} onClick={() => {update_column_visibility_json(column.column_name, !column_visibility_json[column.column_name])}}>
								<div>{column.column_name}</div>
							</Checkbox>
						</div>
					</div>
				))
			}
			</div>
		</div>
	)

	return (
		<div >
			<div id='activities_header'>
				<div className="flex_center px-4 py-3" style={{justifyContent:'space-between'}}>
					<div className="flex_property">
                        <b>Activities</b>
                        <Tooltip
                            title={column_selector}
                            color='white'
                            placement='left'
                            trigger={'click'}
                            overlayStyle={{borderRadius:'16px', marginRight:'30px', maxWidth:'unset'}}
                            overlayInnerStyle={{ maxHeight:'380px', overflow:'hidden', marginBottom:'10px'}}
                            style={{borderRadius:'16px', width:'400px'}}
                            fresh={true}
                            open={show_column_selector}
                            onOpenChange={() => set_show_column_selector(!show_column_selector)}
                        >
                            <div className='flex_center p-2' style={{}}>
                                <i className='fa fa-cog cp' onClick={() => set_show_column_selector(!show_column_selector)} style={{fontSize:'18px'}} />
                            </div>  
                        </Tooltip>
                    </div>
					<div className="flex_center">
						<ISearch search_text={search_string} set_search_text={set_search_string}/>
						<Button color="primary" onClick={() => {set_show_add_edit_activity(true); set_mode('add')}}>Create</Button>
					</div>
				</div>
				{
					is_team_lead || (user_details && user_details.role && user_details.role.store && user_details.role.store.length && user_details.role.store.includes('customer_success_admin'))?
					<div className="flex_property mx-2 p-3" style={{fontSize:'12px', backgroundColor:'white'}}>
						<div className="mr-2">Assigned to:</div>
						<Select 
							// closeMenuOnSelect={false}
							components={animatedComponents}
							value = {selected_users}
							isMulti
							options={user_select_options}
							styles={colourStyles4}
							onChange={(selectedOptions) => {set_selected_users(selectedOptions); set_current_page(1)}}
						/>
					</div>:''
				}
				<div className="flex_center mx-2 p-2" style={{justifyContent:'space-between', backgroundColor:'white'}}>
					<div className="custom_time_filter">
						<div
							className={`nav-item`}
							onClick={onclick_all_types}>
							All
						</div>
						{
							activity_types.map((single_type, idx) => (
								<div
									className={`nav-item ${selected_types[single_type] && 'selected'} flex_center`}
									onClick={() => {selected_types[single_type] = !selected_types[single_type]; set_selected_types(JSON.parse(JSON.stringify(selected_types)))}}>
									<i className={`fa fa-${activity_type_icons[single_type]} mr-1`} style={{fontSize:'14px'}}/>
									{convert_to_readable(single_type)}
								</div>
							))
						}
					</div>
					<div className="custom_time_filter">
					{
						time_filters.map((single_filter, i) => (
							<div
								className={`nav-item ${selected_time_filter === single_filter && 'selected'} flex_center`}
								onClick={() => {set_selected_time_filter(single_filter); set_current_page(1)}}>
								{convert_to_readable(single_filter)}
							</div>
						))
					}
					<div
						className={`nav-item ${selected_time_filter === 'custom' && 'selected'} flex_center`}
						onClick={() => {set_show_date_range(true)}}>
						Select Period
					</div>
					</div>
				</div>
			</div>
			<div className="mx-2" id='activities_table' style={{overflow:'hidden', backgroundColor:'white', position:'relative'}}>
			{
				user_details && user_details.id && cs_org_users && cs_org_users.length && display_activities && display_activities.length?
				<ITable style={{fontSize:'13px', whiteSpace:'nowrap'}} style_container={{overflow:'auto', height:'calc(100% - 52px)'}} 
						coloumnSeparated={1} rowSeparated={1} resizable={true} minCellWidth={80} 
						headings={headings} columnWidths={headings && headings.length ? headings.filter(o => column_visibility_json[o.column_name]).map(o => o.columnWidth).join(' ') : 'k'} tableSettings={false}
						column_visibility_json = {column_visibility_json} set_column_visibility_json={set_column_visibility_json} cellHeight={'45px'}
				>
					{/* <ITableHeader style={{position:'sticky', top:0, backgroundColor:'white', zIndex:5}}>
						<ITH style={{position:'sticky', left:0, backgroundColor:'white', minWidth:'61px'}}>Edit</ITH>
						<ITH style={{position:'sticky', left:'60px', backgroundColor:'white',}}>Delete</ITH>
						<ITH>Done <i className="fa fa-sort" onClick={() => sort_by_field('status')} /></ITH>
						<ITH>Subject <i className="fa fa-sort" onClick={() => sort_by_field('subject')} /></ITH>
						<ITH>Contact Person <i className="fa fa-sort" onClick={() => sort_by_field('contact_person')} /></ITH>
						<ITH>Email <i className="fa fa-sort" onClick={() => sort_by_field('email')} /></ITH>
						<ITH>Phone <i className="fa fa-sort" onClick={() => sort_by_field('phone')} /></ITH>
						<ITH>Org <i className="fa fa-sort" onClick={() => sort_by_field('org_id')} /></ITH>
						<ITH>Due Date <i className="fa fa-sort" onClick={() => sort_by_field('due_date', 'date')} /></ITH>
						<ITH>Duration</ITH>
						<ITH>Add Date <i className="fa fa-sort" onClick={() => sort_by_field('created_at', 'date')} /></ITH>
						<ITH>Assigned to <i className="fa fa-sort" onClick={() => sort_by_field('assignee_id')} /></ITH>
						<ITH>Meeting Url</ITH>
						<ITH>Note <i className="fa fa-sort" onClick={() => sort_by_field('note')} /></ITH>
					</ITableHeader> */}
					<ITableBody>
					{
						display_activities && display_activities.length ? display_activities.slice((current_page - 1) * page_size, current_page * page_size).map((activity, idx) => {
							let json = TableData(activity)
							return(
								Object.keys(json).map((key) => (
									<ITableRow className='display_onhover' style={{height:'45px', color:new Date()>new Date(activity.due_date) && activity.status!='done' ? 'red' : (new Date().toDateString()===new Date(activity.due_date).toDateString() && activity.status!='done' ? '#1aae1a' : ''), backgroundColor: activity.status==='done' ? 'rgb(247 247 247)' : '', textDecorationThickness:'1px', }}>
										{column_visibility_json[key]?json[key]:''}
									</ITableRow>
								))
							)
						}):''
					}
					</ITableBody>
				</ITable>:<div className="" style={{textAlign:'center', fontSize:'14px'}}>No Activities <i className="fa fa-search ml-1" /></div>
				}
				<Col xs="12" id='paginator' className="flex_center py-2">
					<TablePagination current_page={current_page} set_current_page={set_current_page} no_of_pages={no_of_pages} page_size={page_size} set_page_size={set_page_size} set_user_selected_page_size={set_user_selected_page_size}/>
				</Col>
			</div>
			<AddEditActivity 
				open={show_add_edit_activity}
				toggle={() => {set_show_add_edit_activity(false); set_mode(''); set_active_activity(''); set_current_org_id('')}}
				mode={mode}
				active_activity={active_activity}
				cs_org_users={cs_org_users}
				user_details={user_details}
				get_activities={get_activities}
				all_orgs = {all_stores}
				set_page_loader={set_page_loader}
				id_from_all_activities_page={current_org_id}
				open_create_activity_modal={(org_id) => {set_mode('add');set_current_org_id(org_id);set_show_add_edit_activity(true)}}
			/>
			<DateRangeModal 
				open={show_date_range}
				toggle={() => set_show_date_range(false)}
				dateRange={[{startDate:filter_start_date, endDate: filter_end_date, key: 'selection'}]}
				setRange={(x,y) => {set_filter_start_date(x); set_filter_end_date(y); set_selected_time_filter('custom')}}
			/>
		</div>
	)
}

export default Activities