const get_non_internal_non_mes_user_count = (all_users) => {
    let non_internal_users = all_users.filter(x => !x.internal).filter(x => !x.deprecated);
    let non_mes_users = non_internal_users.filter(x => {
        let r = Array.from(new Set(x.roles));
        r = r.filter(x => x != 'mes_operator');
        r = r.filter(x => x != 'mes_admin');
        if(r.length > 0) {
            return true
        }
        return false
    });
    return non_mes_users.length
}

export {get_non_internal_non_mes_user_count}
