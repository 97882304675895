
import React, { useState, useEffect, useMemo } from "react";
import { FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Modal, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, Dropdown, } from "reactstrap";
import makeAnimated from 'react-select/animated';
import Select, { StylesConfig } from "react-select";
import { colourStyles2 } from "../../utils/react_select_styles";
import { infurnia_add_ons } from "../../utils/infurnia_add_ons";
import { toastr } from "react-redux-toastr";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import format_date_yyyy_mm_dd from "../../utils/format_date_yyyy_mm_dd";
import country_list from "../../utils/country_list";

const animatedComponents = makeAnimated();
const onboarding_options = [{ onboarding_status: 'not_started', displayName: 'Not Started' }, { onboarding_status: 'ongoing', displayName: 'Ongoing' }, { onboarding_status: 'completed', displayName: 'Completed' }, { onboarding_status: 'not_applicable', displayName: 'Not Applicable' }];

const OrgFilterModal = ({ open,
	toggle,
	internal_filter,
	set_internal_filter,
	enterprise_filter,
	set_enterprise_filter,
	suspended_filter,
	set_suspended_filter,
	selected_cses,
	set_selected_cses,
	se_filter,
	set_se_filter,
	onboarding_filter,
	set_onboarding_filter,
	addon_onboarding_filter,
	set_addon_onboarding_filter,
	addon_onboarding_date_from,
	set_addon_onboarding_date_from,
	addon_onboarding_date_to,
	set_addon_onboarding_date_to,
	cs_org_users,
	cs_executives,
	set_filter_active,
	responsiveness_filter,
	set_responsiveness_filter,
	selected_add_ons,
	set_selected_add_ons,
	selected_domains,
	set_selected_domains,
	country_filter,
	set_country_filter,
	payment_cycle_filter,
	set_payment_cycle_filter,
	filter_fn,
	all_stores
}) => {
	const [enterprise_filter_1, set_enterprise_filter_1] = useState();
	const [suspended_filter_1, set_suspended_filter_1] = useState();
	const [internal_filter_1, set_internal_filter_1] = useState();
	const [se_filter_1, set_se_filter_1] = useState();
	const [onboarding_filter_1, set_onboarding_filter_1] = useState();
	const [addon_onboarding_filter_1, set_addon_onboarding_filter_1] = useState();
	const [addon_onboarding_date_from_1, set_addon_onboarding_date_from_1] = useState();
	const [addon_onboarding_date_to_1, set_addon_onboarding_date_to_1] = useState();
	const [responsiveness_filter_1, set_responsiveness_filter_1] = useState();
	const [selected_cses_1, set_selected_cses_1] = useState();
	const [selected_add_ons_1, set_selected_add_ons_1] = useState();
	const [selected_domains_1, set_selected_domains_1] = useState();
	const [ country_filter_1, set_country_filter_1 ] = useState();
	const [ payment_cycle_filter_1, set_payment_cycle_filter_1 ] = useState();
	const countries_array = useMemo(() => {return all_stores && all_stores.length ? all_stores.map(x => x.country) : []}, [all_stores])

	const reset_fn = () => {
		set_enterprise_filter_1('')
		set_suspended_filter_1('')
		set_internal_filter_1('')
		set_selected_cses_1([])
		set_se_filter_1('')
		set_onboarding_filter_1('')
		set_addon_onboarding_filter_1('')
		set_addon_onboarding_date_from_1('')
		set_addon_onboarding_date_to_1('')
		set_responsiveness_filter_1('')
		set_selected_add_ons_1([])
		set_selected_domains_1([])
		set_country_filter_1([])
		set_payment_cycle_filter_1('')
	}

	const submit_fn = () => {
		set_enterprise_filter(enterprise_filter_1)
		set_suspended_filter(suspended_filter_1)
		set_internal_filter(internal_filter_1)
		set_se_filter(se_filter_1)
		set_onboarding_filter(onboarding_filter_1)
		if(set_addon_onboarding_filter) set_addon_onboarding_filter(addon_onboarding_filter_1)
		if(set_addon_onboarding_date_from) set_addon_onboarding_date_from(addon_onboarding_date_from_1 ? format_date_yyyy_mm_dd(addon_onboarding_date_from_1) : '')
		if(set_addon_onboarding_date_to) set_addon_onboarding_date_to(addon_onboarding_date_to_1 ? format_date_yyyy_mm_dd(addon_onboarding_date_to_1) : '')
		set_responsiveness_filter(responsiveness_filter_1)
		set_selected_cses(selected_cses_1)
		set_selected_add_ons(selected_add_ons_1)
		set_selected_domains(selected_domains_1)
		if(set_country_filter) set_country_filter(country_filter_1)
        if(set_payment_cycle_filter) set_payment_cycle_filter(payment_cycle_filter_1)

		if (enterprise_filter_1 != '' || suspended_filter_1 != '' || internal_filter_1 != '' || (selected_cses_1 && selected_cses_1.length) || se_filter_1 != '' || onboarding_filter_1 != '' || addon_onboarding_filter_1 != '' || addon_onboarding_date_from_1 != '' || addon_onboarding_date_to_1 != '' || responsiveness_filter_1 || payment_cycle_filter_1 || (selected_add_ons_1 && selected_add_ons_1.length) || (selected_domains_1 && selected_domains_1.length) || (country_filter_1 && country_filter_1.length)) {
			set_filter_active(true)
		} else {
			set_filter_active(false)
		}
		filter_fn(enterprise_filter_1, suspended_filter_1, internal_filter_1, selected_cses_1, se_filter_1, onboarding_filter_1, responsiveness_filter_1, selected_add_ons_1, selected_domains_1, addon_onboarding_filter_1, addon_onboarding_date_from_1 ? format_date_yyyy_mm_dd(addon_onboarding_date_from_1) : '', addon_onboarding_date_to_1 ? format_date_yyyy_mm_dd(addon_onboarding_date_to_1) : '', country_filter_1, payment_cycle_filter_1)
		toggle()
	}

	useEffect(() => {
		if (open) {
			if (selected_cses) set_selected_cses_1(selected_cses)
			if (selected_add_ons) set_selected_add_ons_1(selected_add_ons)
			if (selected_domains) set_selected_domains_1(selected_domains)
			set_enterprise_filter_1(enterprise_filter)
			set_suspended_filter_1(suspended_filter)
			set_internal_filter_1(internal_filter)
			set_se_filter_1(se_filter)
			set_onboarding_filter_1(onboarding_filter)
			set_addon_onboarding_filter_1(addon_onboarding_filter)
			set_addon_onboarding_date_from_1(addon_onboarding_date_from)
			set_addon_onboarding_date_to_1(addon_onboarding_date_to)
			set_responsiveness_filter_1(responsiveness_filter)
			set_country_filter_1(country_filter)
			set_payment_cycle_filter_1(payment_cycle_filter)
		}
	}, [open, selected_cses, selected_add_ons, selected_domains, enterprise_filter, suspended_filter, internal_filter, se_filter, onboarding_filter, addon_onboarding_filter, responsiveness_filter, country_filter, payment_cycle_filter]);

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel"> Filter </h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{ overflow: 'auto', maxHeight: '600px' }} >
				<div className="pl-lg-4">
					<Row>
						<Col md="12">
							<FormGroup style={{ zIndex: 6, position: 'relative' }}>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>Domain</div>
									<Select
										closeMenuOnSelect={false}
										components={animatedComponents}
										value={selected_domains_1}
										isMulti
										options={[{value:'Interior Design', label: 'Interior Design'}, {value:'Modular Interior', label: 'Modular Interior'}, {value:'Building Design', label: 'Building Design'}]}
										styles={colourStyles2}
										onChange={(selectedOptions) => {set_selected_domains_1(selectedOptions)}}
									/>
								</label>
							</FormGroup>
							<FormGroup style={{ zIndex: 5, position: 'relative' }}>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>Add Ons</div>
									<Select
										closeMenuOnSelect={false}
										components={animatedComponents}
										value={selected_add_ons_1}
										isMulti
										options={[{ value: 'design', label: 'Only Design' }, { value: 'design_pricing', label: 'Design+Pricing' }, { value: 'design_pricing_mes', label: 'Design+MES' }, ...infurnia_add_ons]}
										styles={colourStyles2}
										onChange={(selectedOptions) => {
											if (selectedOptions && selectedOptions.length > 1 && selectedOptions.find(x => x.value === 'design')) {
												toastr.error(`Can't select 'Only Design' plan along with other Add-ons`)
											} else if (selectedOptions && selectedOptions.length > 1 && selectedOptions.find(x => x.value === 'design_pricing')) {
												toastr.error(`Can't select 'Design+Pricing' plan along with other Add-ons`)
                                            } else if (selectedOptions && selectedOptions.length > 1 && selectedOptions.find(x => x.value === 'design_pricing_mes')) {
												toastr.error(`Can't select 'Design+MES' plan along with other Add-ons`)
                                            } else {
												set_selected_add_ons_1(selectedOptions)
											}
										}}
									/>
								</label>
							</FormGroup>
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>Responsive</div>
									<Input type='select' style={{ height: '36px' }} className='px-2 py-1' value={responsiveness_filter_1} onChange={(e) => set_responsiveness_filter_1(e.target.value)}>
										<option value=''>None</option>
										<option value={true}>Yes</option>
										<option value={false}>No</option>
									</Input>
								</label>
							</FormGroup>
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>Onboarding Status</div>
									<Input type='select' style={{ height: '36px' }} className='px-2 py-1' value={onboarding_filter_1} onChange={(e) => set_onboarding_filter_1(e.target.value)}>
										<option value=''>None</option>
										{
											onboarding_options && onboarding_options.length ? onboarding_options.map((single_status) => (
												<option value={single_status.onboarding_status}>{single_status.displayName}</option>
											)) : ''
										}
									</Input>
								</label>
							</FormGroup>
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>Add-on Onboarding </div>
									<div className="flex_property mb-2">
										<div style={{fontSize:'13px'}}>Add-on selected above will be considered for filtering</div>
									</div>
									<div className="flex_property mb-2">
										<div style={{width:'50%', fontSize:'13px'}}>Onboarding Status</div>
										<Input type='select' style={{ height: '36px', width:'50%', fontSize:'13px' }} className='px-2 py-1' value={addon_onboarding_filter_1} onChange={(e) => set_addon_onboarding_filter_1(e.target.value)}>
											<option value=''>None</option>
											{
												onboarding_options && onboarding_options.length ? onboarding_options.map((single_status) => (
													<option value={single_status.onboarding_status}>{single_status.displayName}</option>
												)) : ''
											}
										</Input>
									</div>
									<div className="flex_property mb-2">
										<div style={{width:'50%', fontSize:'13px'}}>Onboarding Date From</div>
										<DatePicker 
											style={{width:'50%', fontSize:'13px'}}
											format="YYYY-MM-DD"
											onChange={(dateString) => set_addon_onboarding_date_from_1(dateString)}
											value={addon_onboarding_date_from_1 ? dayjs(addon_onboarding_date_from_1, new Date(addon_onboarding_date_from_1), 'YYYY-MM-DD') : ''}
										/>
									</div>
									<div className="flex_property mb-2">
										<div style={{width:'50%', fontSize:'13px'}}>Onboarding Date To</div>
										<DatePicker 
											style={{width:'50%', fontSize:'13px'}}
											format="YYYY-MM-DD"
											onChange={(dateString) => {console.log(dateString); set_addon_onboarding_date_to_1(dateString)}}
											value={addon_onboarding_date_to_1 ? dayjs(addon_onboarding_date_to_1, new Date(addon_onboarding_date_to_1), 'YYYY-MM-DD') : ''}
										/>
									</div>
								</label>
							</FormGroup>
							<FormGroup style={{ zIndex: 5, position: 'relative' }}>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>CS Rep</div>
									<Select
										closeMenuOnSelect={false}
										components={animatedComponents}
										value={selected_cses_1}
										isMulti
										options={cs_executives && cs_executives.length ? cs_executives.map(x => { return { value: x.user_id, label: x.first_name } }) : []}
										styles={colourStyles2}
										onChange={(selectedOptions) => set_selected_cses_1(selectedOptions)}
									/>
								</label>
							</FormGroup>
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>BD Rep</div>
									<Input type='select' style={{ height: '36px' }} className='px-2 py-1' value={se_filter_1} onChange={(e) => set_se_filter_1(e.target.value)}>
										<option value=''>None</option>
										{
											cs_org_users && cs_org_users.length ? cs_org_users.map((single_user) => (
												<option value={single_user.id}>{single_user.first_name}</option>
											)) : ''
										}
									</Input>
								</label>
							</FormGroup>
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>Enterprise / SMB</div>
									<Input type='select' style={{ height: '36px' }} className='px-2 py-1' value={enterprise_filter_1} onChange={(e) => set_enterprise_filter_1(e.target.value)}>
										<option value=''>None</option>
										<option value={'enterprise'}>Enterprise</option>
										<option value={'smb'}>SMB</option>
									</Input>
								</label>
							</FormGroup>
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>Internal Org</div>
									<Input type='select' style={{ height: '36px' }} className='px-2 py-1' value={internal_filter_1} onChange={(e) => set_internal_filter_1(e.target.value)}>
										<option value=''>None</option>
										<option value={true}>True</option>
										<option value={false}>False</option>
									</Input>
								</label>
							</FormGroup>
							<FormGroup style={{ zIndex: 4, position: 'relative' }}>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>Country</div>
									<Select
										closeMenuOnSelect={false}
										components={animatedComponents}
										value={country_filter_1}
										isMulti
										options={country_list.filter(o => countries_array.includes(o.code)).map(x => ({value:x.code, label:x.name}))}
										styles={colourStyles2}
										onChange={(selectedOptions) => set_country_filter_1(selectedOptions)}
									/>
								</label>
							</FormGroup>
							<FormGroup style={{ zIndex: 3, position: 'relative' }}>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>Quarterly/Annual</div>
									<Input type='select' style={{ height: '36px' }} className='px-2 py-1' value={payment_cycle_filter_1} onChange={(e) => set_payment_cycle_filter_1(e.target.value)}>
										<option value=''>All</option>
										<option value={3}>Quarterly</option>
										<option value={12}>Annual</option>
									</Input>
								</label>
							</FormGroup>
							{/* <FormGroup>
					<label className="form-control-label" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0}}>
						<div className='mb-2'>Due Date</div>
						<Input type='select' style={{height:'36px'}} className='px-2 py-1' value={churn_date_filter} onChange={(e) => set_churn_date_filter(e.target.value)}>
							<option value=''>None</option>
							<option value='today'>Today</option>
							<option value='yesterday'>Yesterday</option>
							<option value='tomorrow'>Tomorrow</option>
							<option value='last_7_days'>Last 7 days</option>
							<option value='next_7_days'>Next 7 days</option>
							<option value='custom'>Custom</option>
						</Input>
						{
							churn_date_filter == 'custom' ?
							<>
								<Row className='mt-3'>
									<Col md="6">
										<FormGroup>
											<label className="form-control-label mr-2" htmlFor="input-address" >
												From
											</label>
											<DatePicker
												format="YYYY-MM-DD"
												onChange = {(date, dateString) => set_churn_date_from(dateString)}
												value = {churn_date_from ? dayjs(churn_date_from, new Date(churn_date_from), 'YYYY-MM-DD') : ''}
												// disabledDate={(d) => new Date(d) < new Date(today)}
											/>
										</FormGroup>
									</Col>
									<Col md="6">
										<FormGroup>
											<label className="form-control-label mr-2" htmlFor="input-address" >
												To
											</label>
											<DatePicker
												format="YYYY-MM-DD"
												onChange = {(date, dateString) => set_churn_date_to(dateString)}
												value = {churn_date_to ? dayjs(churn_date_to, new Date(churn_date_to), 'YYYY-MM-DD') : ''}
												// disabledDate={(d) => new Date(d) < new Date(from_date)}
											/>
										</FormGroup>
									</Col>
								</Row>
							</>:""
						}
					</label>
                    </FormGroup> */}
						</Col>
					</Row>
				</div>
			</div>
			<div className="modal-footer" style={{ height: '56px' }}>
				<Button className='white_button mr-2' onClick={reset_fn}>Reset</Button>
				<Button color="primary" onClick={submit_fn}>Submit</Button>
			</div>
		</Modal>
	)
}

export default OrgFilterModal